<template>
  <v-container fluid class="pt-0">
    <v-row>
      <v-img
        :height="$vuetify.breakpoint.smAndUp ? '60vh' : '100vh'"
        src="/imagenes/background.jpeg"
        class="image"
      >
        <v-row
          justify="center"
          align="center"
          class="flex-column"
          style="height: 100%"
        >
          <v-col cols="11" md="7" lg="5" class="text-center">
            <v-img
              contain
              :height="$vuetify.breakpoint.mdAndUp ? '200' : '180'"
              class="my-6"
              style="filter: brightness(0) invert(1)"
              src="https://tepic.sfo3.cdn.digitaloceanspaces.com/image_tramites/Identidad%20Gobierno%20de%20Tepic-2.png"
            ></v-img>
            <search-bar
              showSearch
              :search="searchValue"
              v-on:searchEmit="updateSearch"
              :action="onPress"
            />
            <v-btn
              color="white"
              outlined
              @click="onPress"
              rounded
              large
              class="mt-7 custom-bg"
            >
              <strong class="boguefont">Buscar trámite</strong>
            </v-btn>
          </v-col>
        </v-row>
      </v-img>
    </v-row>
    <v-row class="py-12 primary" align="center" justify="center">
      <v-col cols="12" md="9" lg="6">
        <h2 class="white--text text-center boguefont">
          Bienvenido al Sistema Municipal de Trámites y Servicios
        </h2>
        <h3 class="font-weight-regular mt-4 text-center white--text boguelight">
          Aquí encontrarás todos los trámites de las dependencias del <br />
          H. XLIII Ayuntamiento de Tepic
        </h3>
      </v-col>
    </v-row>
    <v-row class="py-6 py-md-12 px-auto px-lg-12">
      <v-col cols="12">
        <h2 class="primary--text mb-3 text-center boguefont">Trámites y servicios</h2>
        <h3 class="grey--text text-center boguelight">Consulta por área o dirección</h3>
        <v-row justify="center" align="center boguelight" class="py-12">
          <v-col
            cols="12"
            sm="6"
            md="4"
            xl="3"
            v-for="(item, n) in dependecieList"
            :key="n"
          >
            <dependency-card
              :imgsrc="item.bgimage"
              :imgDep="item.icon"
              :titleText="item.name"
              :clickCard="
                () => {
                  onPressCard(item);
                }
              "
            ></dependency-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-footer padless absolute>
      <v-col class="primary py-4 text-center white--text" cols="12">
        <strong class="boguelight">Copyright © 2024 H. XLIII Ayuntamiento de Tepic </strong>
      </v-col>
    </v-footer>
  </v-container>
</template>
<style scoped>
.initial {
  height: 40vh;
  z-index: 1;
}
.login-text {
  position: absolute;
  top: 10px;
  right: 20px;
  bottom: 0px;
  left: 0px;
  justify-content: flex-end;
}
.transparent-back {
  background: rgba(255, 255, 255, 0.3);
}
.image::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.custom-bg {
  background-color: rgba(0, 0, 0, 0.2); /* Fondo negro con 50% de transparencia */
  color: white; /* Asegura que el texto sea visible */
}

.boguefont {
  font-family: 'Bogue', sans-serif;
}

.boguelight{
  font-family: 'Bogue Light', sans-serif;
}

</style>
<script>
import SearchBar from "../components/SearchBar.vue";
import DependencyCard from "../components/DependencyCard.vue";
import './../assets/css/style.css';
import { mapState } from "vuex";
export default {
  components: { SearchBar, DependencyCard },
  mounted() {
    this.$store.dispatch("procedures/getDependencyList").then(() => {

    });
  },
  data: () => ({
    search: "",
  }),
  methods: {
    onPressCard(item) {
      this.$store.dispatch("procedures/setSearch", item.name);
      this.$router.push("/tramites");
    },
    onPress() {
      this.$router.push("/tramites");
    },
    updateSearch(text) {
      this.$store.dispatch("procedures/setSearch", text);
    },
  },
  computed: {
    ...mapState({
      userFlag: (state) => state.user.isUserLogued,
      searchValue: (state) => state.procedures.search,
      dependecieList: (state) => state.procedures.dependecyList,
      userToken: (state) => state.user.userToken,
      userLogued: (state) => state.user.loguedUser,
    }),
  },
};
</script>
