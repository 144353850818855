<template lang="">
  <v-container fluid class="boguelight">
    <v-row class="pt-7"> 
      <v-col cols="12">
        <h1>Trámites archivados</h1>
        <h2 class="secondary--text font-weight-regular">{{ itemProcedure.length }} resultados</h2>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="searchProcedures"
          rounded
          outlined
          hide-details
          label="Buscar"
          prepend-inner-icon="mdi-magnify"
          class="mt-3 mb-3"
          clearable
        ></v-text-field>
      </v-col>
    </v-row> 
   <v-data-table
     :headers="headersProcedures"
     :items="itemProcedure"
     class="elevation-1 mytable"
     :items-per-page="10"
     d-initial-sort
     item-key="id"
     :search="searchProcedures"
    >
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | birthdate }}
      </template>
      <template v-slot:item.user.name="{ item }">
        {{ item.user.name }}{{ " " }}{{ item.user.lastname }}
      </template>
      <template v-slot:item.expires_at="{ item }">
        {{ item.expires_at | birthdate }}
      </template>
      <template v-slot:item.status_service="{ item }">
        <v-chip
          class="ma-2"
          color="success"
          v-if="item.status_service == 'Documentos Revisados'"
        >
          Revisados
        </v-chip>
        <v-chip class="ma-2" color="warning"  v-if="item.status_service == 'Nuevo tramite'"> Nuevo Trámite </v-chip>
        <v-chip class="ma-2" color="warning"  v-if="item.status_service == 'En revisión'"> En revisión </v-chip>
        <v-chip class="ma-2" color="warning"  v-if="item.status_service == 'En seguimiento'"> En seguimiento </v-chip>
        <v-chip class="ma-2" color="warning"  v-if="item.status_service == 'Nueva actualización'"> Nueva actualización</v-chip>
      </template>
    </v-data-table>  
    <!-- <template v-slot:item.actions="{ item }" class="text-xs-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon text @click="restoreProcedure(item)">
              <v-icon class="mr-2" color="primary">mdi-archive-arrow-up</v-icon>
            </v-btn>
          </template>
          Restablecer trámite 
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="openModalDelete(item)" v-on="on" icon text>
              <v-icon class="mr-2" color="primary">mdi-cancel</v-icon>
            </v-btn>
          </template>
          Eliminar
        </v-tooltip> 
    </template> -->  
     <v-dialog
      v-model="showModalProcedureDelete"
      max-width="800"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card :tile="$vuetify.breakpoint.smAndDown">
        <v-card-title class="primary white--text justify-center">
          <h4 class="text-center">Eliminar trámite</h4>
        </v-card-title>

        <h3 class="text-center mt-5 mb-3">
          ¿Seguro que desea eliminar este trámite?
        </h3>

        <v-card-actions class="justify-center">
          <v-btn color="error" text @click="showModalProcedureDelete = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="EliminarTramite"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  mounted() {
    this.cargarDatos();
  },
  data: () => ({
    searchProcedures: "",
    showModalProcedureDelete: false,
    itemProcedure: [],
    idborrar: "",
    idRestore: "",

    headersProcedures: [
      {
        text: "Folio",
        align: "start",
        sortable: true,
        value: "folio",
      },
      {
        text: "Contribuyente",
        value: "user.name",
        sortable: false,
        align: "center",
      },
      {
        text: "Trámite solicitado",
        value: "procedure.name",
        sortable: true,
        align: "center",
      },
      {
        text: "Fecha de creación",
        value: "created_at",
        sortable: true,
        align: "center",
      },
      {
        text: "Fecha de asignación",
        value: "expires_at",
        sortable: true,
        align: "center",
      },
      {
        text: "Estatus documentación",
        value: "status_service",
        sortable: false,
        align: "center",
      },
      // {
      //   text: "Accions",
      //   sortable: true,
      //   align: "center",
      //   value: "actions",
      //   class: "black--text",
      // },
    ],
  }),

  methods: {
    cargarDatos() {
      this.$store.dispatch("user/userProceduresArchive").then((data) => {
        this.itemProcedure = data;
        console.log(data);
      });
    },

    restoreProcedure() {
      let params = {
        id: this.idborrar,
      };
      this.$store
        .dispatch("user/restoreProcedure", params)
        .then(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Trámite restablecido correctamente."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "success");
          this.$store.dispatch("alerts/setSnackbar", true);
          this.showModalCrearTramite = false;
          this.cargarDatos();
        })
        .catch(() => {
          this.$store.dispatch("alerts/setSnackbarText", "Algo salió mal.");
          this.$store.dispatch("alerts/setSnackbarColor", "error");
          this.$store.dispatch("alerts/setSnackbar", true);
        });
      this.showModalProcedureDelete = false;
    },
    openModalDelete(item) {
      this.idborrar = item.id;
      this.showModalProcedureDelete = true;
      console.log(item);
    },
    EliminarTramite() {
      let params = {
        id: this.idborrar,
      };
      this.$store
        .dispatch("user/deleteProcedureForever", params)
        .then(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Trámite eliminado correctamente."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "success");
          this.$store.dispatch("alerts/setSnackbar", true);
          this.showModalCrearTramite = false;
          this.cargarDatos();
        })
        .catch(() => {
          this.$store.dispatch("alerts/setSnackbarText", "Algo salió mal.");
          this.$store.dispatch("alerts/setSnackbarColor", "error");
          this.$store.dispatch("alerts/setSnackbar", true);
        });
      this.showModalProcedureDelete = false;
    },
  },
  computed: {},
};
</script>
