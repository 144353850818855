var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.userFlag == false)?_c('v-btn',{staticClass:"boguelight",attrs:{"dark":"","small":"","text":"","to":"/ingresar"}},[_vm._v(" Iniciar sesión ")]):(
      _vm.userLogued.role == 'Administrador' ||
      _vm.userLogued.role == 'Super Administrador'
    )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","text":"","small":""}},on),[_vm._v(" Administrar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',[(_vm.userLogued.role == 'Super Administrador')?_c('v-list-item',{attrs:{"to":"/users"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-multiple")]),_c('v-list-item-title',{staticClass:"black--text"},[_vm._v(" Administrar usuarios ")])],1):_vm._e(),(_vm.userLogued.role == 'Administrador')?_c('v-list-item',{attrs:{"to":"/admin-tramites"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-note-edit-outline")]),_c('v-list-item-title',[_vm._v(" Administrar trámites ")])],1):_vm._e(),_c('v-divider'),(_vm.userLogued.role == 'Administrador')?_c('v-list-item',{attrs:{"to":"/admin"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_c('v-list-item-title',[_vm._v(" Trámites pendientes ")])],1):_vm._e(),_c('v-divider'),_c('v-divider'),(_vm.userLogued.role == 'Administrador')?_c('v-list-item',{attrs:{"to":"/tramites-archives"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-briefcase-account")]),_c('v-list-item-title',{staticClass:"black--text"},[_vm._v(" Trámites archivados ")])],1):_vm._e(),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.logOut}},[_c('v-icon',{attrs:{"color":"error","left":""}},[_vm._v("mdi-logout-variant")]),_c('v-list-item-title',{staticClass:"error--text"},[_vm._v(" Cerrar sesión ")])],1)],1)],1):_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":"","text":"","small":""}},on),[_vm._v(" Administrar "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"color":"white"}},[_c('v-list-item',{attrs:{"to":"/mis-tramites"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_c('v-list-item-title',{staticClass:"black--text"},[_vm._v(" Mis trámites ")])],1),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.logOut}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-logout")]),_c('v-list-item-title',{staticClass:"black--text"},[_vm._v(" Cerrar sesión ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }