var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"boguelight",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pt-7"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Trámites archivados")]),_c('h2',{staticClass:"secondary--text font-weight-regular"},[_vm._v(_vm._s(_vm.itemProcedure.length)+" resultados")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mt-3 mb-3",attrs:{"rounded":"","outlined":"","hide-details":"","label":"Buscar","prepend-inner-icon":"mdi-magnify","clearable":""},model:{value:(_vm.searchProcedures),callback:function ($$v) {_vm.searchProcedures=$$v},expression:"searchProcedures"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 mytable",attrs:{"headers":_vm.headersProcedures,"items":_vm.itemProcedure,"items-per-page":10,"d-initial-sort":"","item-key":"id","search":_vm.searchProcedures},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("birthdate")(item.created_at))+" ")]}},{key:"item.user.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+_vm._s(" ")+_vm._s(item.user.lastname)+" ")]}},{key:"item.expires_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("birthdate")(item.expires_at))+" ")]}},{key:"item.status_service",fn:function(ref){
var item = ref.item;
return [(item.status_service == 'Documentos Revisados')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"success"}},[_vm._v(" Revisados ")]):_vm._e(),(item.status_service == 'Nuevo tramite')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_vm._v(" Nuevo Trámite ")]):_vm._e(),(item.status_service == 'En revisión')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_vm._v(" En revisión ")]):_vm._e(),(item.status_service == 'En seguimiento')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_vm._v(" En seguimiento ")]):_vm._e(),(item.status_service == 'Nueva actualización')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_vm._v(" Nueva actualización")]):_vm._e()]}}])}),_c('v-dialog',{attrs:{"max-width":"800","fullscreen":_vm.$vuetify.breakpoint.smAndDown,"persistent":""},model:{value:(_vm.showModalProcedureDelete),callback:function ($$v) {_vm.showModalProcedureDelete=$$v},expression:"showModalProcedureDelete"}},[_c('v-card',{attrs:{"tile":_vm.$vuetify.breakpoint.smAndDown}},[_c('v-card-title',{staticClass:"primary white--text justify-center"},[_c('h4',{staticClass:"text-center"},[_vm._v("Eliminar trámite")])]),_c('h3',{staticClass:"text-center mt-5 mb-3"},[_vm._v(" ¿Seguro que desea eliminar este trámite? ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.showModalProcedureDelete = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.EliminarTramite}},[_vm._v(" Confirmar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }