<template>
  <v-container fluid class="pt-10">
    <v-row>
      <v-col cols="12" md="6" lg="3" class="boguelight">
        <v-card elevation="1" :loading="isLoading">
          <p
            class="
              justify-center
              align-center
              text-center
              mb-0
              font-weight-bold
              grey
              lighten-3
              py-2
              text-uppercase
            "
          >
            {{ procedureDetail.dependecy.name }}
          </p>
          <v-card-text>
            <v-col align="center" justify="center" cols="12">
              <h2 class="primary--text">{{ procedureDetail.name }}</h2>
              <br />
              <p class="text--center">Población objetivo</p>
              <h3 class="black--text text--center">
                {{ procedureDetail.objetive }}
              </h3>
            </v-col>
            <v-col cols="12" align="center" justify="center">
              <div v-if="this.edad>=65">
                <p class="text--center mb-3">Costo con descuento</p>
                <h3 class="text--center dataText">
                  ${{ (procedureDetail.price)-(procedureDetail.price*.30) }}
                </h3>
              </div>
              <div v-else>
                <p class="text--center mb-3">Costo</p>
                <h3 class="text--center dataText">
                  ${{ procedureDetail.price }}
                </h3>
              </div>
            </v-col>
            <v-col cols="12" align="center" justify="center">
              <p class="text--center mb-3">
                Plazos para la conclusión del trámite o servicio
              </p>
              <h3
                class="text--center dataText"
                v-if="procedureDetail.validate_service == null"
              >
                No disponible
              </h3>
              <h3 class="text--center dataText" v-else>
                {{ procedureDetail.validate_service }}
              </h3>
            </v-col>
            <v-col cols="12" align="center" justify="center">
              <p class="text--center mb-3">
                Vigencia de los resultados del trámite o servicio
              </p>
              <h3 class="text--center dataText">6 meses</h3>
            </v-col>
            <v-col cols="12" align="center" justify="center">
              <p class="text--center">Te sirve para</p>
              <h3 class="text--center dataText">
                {{ procedureDetail.serves_for }}
              </h3>
            </v-col>
            <v-col cols="12" align="center" justify="center">
              <p class="text--center mb-3">Modalidad</p>
              <h3 class="text--center dataText">
                {{ procedureDetail.modality }}
              </h3>
            </v-col>
            <v-col cols="12" align="center" justify="center">
              <p class="text--center mb-3">Tipo de trámite</p>
              <h3 class="text--center dataText">
                {{ procedureDetail.type }}
              </h3>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6" class="boguelight">
        <v-card flat elevation="1" :loading="isLoading">
          <p
            class="
              justify-center
              align-center
              text-center
              mb-0
              font-weight-bold
              grey
              lighten-3
              py-2
              text-uppercase
            "
          >
            Requisitos del trámite
          </p>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                v-for="(item, index) in procedureDetail.requirements"
                :key="index"
              >
                <requirement-card
                  :requirementTitle="item.name"
                  :requirementType="item.type"
                  isGrey
                ></requirement-card>
              </v-col>
            </v-row>            
            <v-row align="center" justify="center" v-if="userLogued.role == 'Usuario'">
              <v-col cols="12" md="8" lg="5">
                <Button
                  color="primary"
                  :action="initTramit"
                  text="Iniciar mi tramite"
                /> 
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" md="8" lg="5">
                <Button
                  color="primary"
                  :action="downloadFile"
                  text="Descargar Padrón" 
                  whiteButton 
                /> 
              </v-col>
            </v-row>            
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="3" class="boguelight">
        <v-card flat elevation="1" :loading="isLoading">
          <p
            class="
              justify-center
              align-center
              text-center
              mb-0
              font-weight-bold
              grey
              lighten-3
              py-2
              text-uppercase
            "
          >
            Dirección
          </p>
          <v-card-text>
            <v-col cols="12" align="center" justify="center">
              <v-avatar color="primary" class="mb-4">
                <v-icon dark> mdi-map-marker </v-icon>
              </v-avatar>
              <h3 class="text--center black--text">
                {{ procedureDetail.dependecy.address }}
              </h3>
            </v-col>
            <v-col cols="12" align="center" justify="center">
              <p class="text--center mb-0">Horario</p>
              <h3 class="text--center black--text">
                {{ procedureDetail.dependecy.schedule }}
              </h3>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template> 
<script>

  import Button from "../components/Button.vue";
  import RequirementCard from "../components/RequirementCard.vue";
  import { mapState } from "vuex";
  import moment from "moment";
  export default {
    components: { Button, RequirementCard },
    mounted() {
      this.calcularEdad();
      let params = {
        id: this.procedureDetail.id,
      };
      this.$store
        .dispatch("procedures/getProcedureDetail", params)
        .then(() => {});
    },
    data: () => ({
      publicPath: process.env.BASE_URL,
      edad: 0,
    }),
    methods: {
    downloadFile(){
      let params ={ 
        id:this.procedureDetail.id
      }
      this.$store.dispatch("procedures/dowloadProcedurePattern", params).then((data) => {
        var wnd = window.open("about:blank", "", "_blank");
        wnd.document.write(""+ data.data);
      }).catch(() => {
            this.$store.dispatch(
              "alerts/setSnackbarText",
              "Algo salio mal , intentelo más tarde"
            );
            this.$store.dispatch("alerts/setSnackbarColor", "error");
            this.$store.dispatch("alerts/setSnackbar", true);
            this.loadingLogin = false;
          });
      //Funcion donde se descargará el padron del tramite solicitado
    },    
      initTramit() {
        if (this.userFlag == false) {
          this.$router.push("/ingresar");
        } else {
          let user = {
            procedure_id: this.procedureDetail.id,
            user_id: this.userLogued.id,
            folio: "",
          };
          this.$store.dispatch("user/initNewTramit", user).then(() => {
            this.$router.push("/my-procedures-list");
          });
        }
      },
      calcularEdad(){
        var now = moment(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        );
        var end = moment(this.userLogued.born_date);
        var duration = moment.duration(now.diff(end));
        var years = duration.years();
        this.edad = years;
      },
      onClickImage() {
        this.$router.push("/");
      },
    },
    computed: {
      ...mapState({
        procedureDetail: (state) => state.procedures.procedureDetail,
        userLogued: (state) => state.user.loguedUser,
        userFlag: (state) => state.user.isUserLogued,
        isLoading: (state) => state.procedures.isLoading,
      }),
    },
  };
</script>
<style scoped>
  .v-card {
    border-radius: 0.5rem !important;
  }
  .TitleT {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #393e46;
  }
  .textP {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.07px;
    color: #9b2040;
  }
  .textI {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: #222831;
  }
  .login-container {
    height: 100%;
    width: 100%;
  }
  .backgroundGray {
    background: rgba(247, 247, 248, 255);
  }
  .createAccount {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: #9b2040;
  }
  .seconText {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 23px;
    color: #222831;
  }
  .inputC {
    box-sizing: border-box;
    border-radius: 12px;
  }
  .dataText {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.07px;
    color: #222831;
  }
  .textLD {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.07px;
    color: #222831;
  }
  .reqP1 {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .reqP2 {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .boguefont {
  font-family: 'Bogue', sans-serif;
}

.boguelight{
  font-family: 'Bogue Light', sans-serif;
}
</style>
