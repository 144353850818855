<template>
  <v-app class="alternate-font">
    <v-app-bar app color="primary" flat>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        dark
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/">
          <v-img
            contain
            :height="$vuetify.breakpoint.smAndDown ? 50 : 60"
            :width="$vuetify.breakpoint.smAndDown ? 100 : 140"
            src="https://tepic.sfo3.cdn.digitaloceanspaces.com/image_tramites/Identidad%20Gobierno%20de%20Tepic%20Logo.png"
            style="filter: brightness(0) invert(1)"
          ></v-img>
        </router-link>
      </v-toolbar-title>
      <v-container class="py-0 fill-height" fluid>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
        <v-responsive v-if="$route.path != '/'">
          <v-text-field
            class="boguelight"
            dense
            flat
            hide-details
            rounded
            v-model="search"
            solo-inverted
            color="primary"
            dark
            clearable
            label="Buscar trámite o servicio"
            prepend-inner-icon="mdi-magnify"
            v-on:keyup.enter="onPress"
          ></v-text-field>
        </v-responsive>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          dark
          class="mx-1 boguelight"
          small
          text
          to="/"
        >
          Inicio
        </v-btn>
        <!-- <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          dark
          class="mx-1"
          small
          text
          to="/oficialia"
        >
          Oficialia de partes
        </v-btn> -->
        <login-button v-if="$vuetify.breakpoint.mdAndUp" />
      </v-container>
    </v-app-bar>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="drawer"
      app
      clipped
      temporary
    >
      <v-list>
        <v-list-item link exact to="/">
          <v-list-item-content>
            <v-list-item-title>Inicio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link exact to="/oficialia">
          <v-list-item-content>
            <v-list-item-title> Oficialia de partes </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <login-list-item />
      </v-list>
    </v-navigation-drawer>

    <v-content>
      <router-view></router-view>
    </v-content>
    <v-snackbar
      :value="snackbar"
      :color="snackbarColor"
      rounded="pill"
      right
      :timeout="2500"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import LoginButton from "./components/LoginButton.vue";
import LoginListItem from "./components/LoginListItem.vue";
import './assets/css/style.css';
export default {
  components: { LoginButton, LoginListItem },
  name: "App",
  created() {
    // miStorage.clear();
    let miStorage = window.localStorage;
    let usr_data = miStorage.getItem("usr_data");
    let usr_acctok = miStorage.getItem("usr_acctok");
    let usr_tok_expires_at = miStorage.getItem("usr_tok_expires_at");
    if (!!usr_data && !!usr_acctok && !!usr_tok_expires_at) {
      if (this.$moment().isSameOrBefore(this.$moment(usr_tok_expires_at))) {
        this.$store.dispatch("user/setUserLoggedIn", {
          user: JSON.parse(usr_data),
          accessToken: usr_acctok,
        });
      } else {
        miStorage.removeItem("usr_data");
        miStorage.removeItem("usr_acctok");
        miStorage.removeItem("usr_tok_expires_at");
      }
    }
  },
  beforeDestroy() {
    let miStorage = window.localStorage;
    miStorage.removeItem("usr_data");
    miStorage.removeItem("usr_acctok");
    miStorage.removeItem("usr_tok_expires_at");
  },
  data: () => ({
    offsetTop: 0,
    search: "",
    drawer: false,
  }),
  methods: {
    logOut() {
      this.$store.dispatch("user/logout").then(() => {
        let miStorage = window.localStorage;
        miStorage.removeItem("usr_data");
        miStorage.removeItem("usr_acctok");
        miStorage.removeItem("usr_tok_expires_at");
      });
      this.$router.push("/");
    },

    onPress() {
      let params = {};
      if (this.search == "" || this.search == null) {
        this.$store.dispatch("procedures/setSearch", "");
        this.$router.push("/tramites");
        params.search = "";
      } else {
        this.$store.dispatch("procedures/setSearch", this.search);
        params.search = this.searchValue;
      }
      this.$store.dispatch("procedures/setProcedureList", params).then(() => {
        if (
          !this.$route.name.includes("tramites") ||
          this.$route.path == "/mis-tramites"
        ) {
          this.$router.push("/tramites");
        }
      });
    },
  },
  computed: {
    ...mapState({
      searchValue: (state) => state.procedures.search,
      userFlag: (state) => state.user.isUserLogued,
      snackbar: (state) => state.alerts.snackbar,
      snackbarColor: (state) => state.alerts.snackbarColor,
      snackbarText: (state) => state.alerts.snackbarText,
      userLogued: (state) => state.user.loguedUser,
    }),
  },
};
</script>
<style>
.transparent-back {
  background: rgba(255, 255, 255, 0.3);
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.alternate-font {
  font-family: "Montserrat" !important;
}
.boguefont {
  font-family: 'Bogue', sans-serif;
}

.boguelight{
  font-family: 'Bogue Light', sans-serif;
}
</style>
