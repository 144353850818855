<template>
  <div>
    <v-btn dark small class="boguelight" text to="/ingresar" v-if="userFlag == false">
      Iniciar sesión
    </v-btn>
    <v-menu
      v-else-if="
        userLogued.role == 'Administrador' ||
        userLogued.role == 'Super Administrador'
      "
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn dark text small v-on="on">
          Administrar
          <v-icon right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          to="/users"
          v-if="userLogued.role == 'Super Administrador'"
        >
          <v-icon left>mdi-account-multiple</v-icon>
          <v-list-item-title class="black--text">
            Administrar usuarios
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          to="/admin-tramites"
          v-if="userLogued.role == 'Administrador'"
        >
          <v-icon left>mdi-note-edit-outline</v-icon>
          <v-list-item-title> Administrar trámites </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item to="/admin" v-if="userLogued.role == 'Administrador'">
          <v-icon left>mdi-account</v-icon>
          <v-list-item-title> Trámites pendientes </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <!-- <v-list-item to="/documentos" v-if="userLogued.role == 'Administrador'">
          <v-icon left>mdi-file-sign</v-icon>
          <v-list-item-title> Oficialia de partes </v-list-item-title>
        </v-list-item> -->
        <v-divider></v-divider>
        <v-list-item
          to="/tramites-archives"
          v-if="userLogued.role == 'Administrador'"
        >
          <v-icon left class="mr-2">mdi-briefcase-account</v-icon>
          <v-list-item-title class="black--text">
            Trámites archivados
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="logOut">
          <v-icon color="error" left>mdi-logout-variant</v-icon>
          <v-list-item-title class="error--text">
            Cerrar sesión
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu v-else offset-y>
      <template v-slot:activator="{ on }">
        <v-btn dark text small v-on="on">
          Administrar
          <v-icon right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list color="white">
        <v-list-item to="/mis-tramites">
          <v-icon left>mdi-account</v-icon>
          <v-list-item-title class="black--text">
            Mis trámites
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="logOut">
          <v-icon left>mdi-logout</v-icon>
          <v-list-item-title class="black--text">
            Cerrar sesión
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({}),
  methods: {
    logOut() {
      try {
        this.$store.dispatch("user/logout");
      } catch (error) {
        console.log(error);
      }
      let miStorage = window.localStorage;
      miStorage.removeItem("usr_data");
      miStorage.removeItem("usr_acctok");
      miStorage.removeItem("usr_tok_expires_at");
      this.$router.push("/");
    },
  },
  computed: {
    ...mapState({
      userFlag: (state) => state.user.isUserLogued,
      searchValue: (state) => state.procedures.search,
      dependecieList: (state) => state.procedures.dependecyList,
      userToken: (state) => state.user.userToken,
      userLogued: (state) => state.user.loguedUser,
    }),
  },
};
</script>
<style scoped>
.boguefont {
  font-family: 'Bogue', sans-serif;
}

.boguelight{
  font-family: 'Bogue Light', sans-serif;
}
</style>