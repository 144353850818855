import Vue from "vue";
import Vuetify from "vuetify/lib";
Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#74143c",
        error: "#EE0000",
        info: "#2094f7",
        success: "#038902",
        warning: "#FF8600",
        black: "#000000",
        gray: "#E5E5E5",
        darkgray: "#222831",
        blueGob: "#21A6A6",
        greenPayment: "#009F7E",
      },
    },
  },
});
