var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg mt-10 boguelight",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-block pt-8"},[_c('h2',{staticClass:"secondary--text font-weight-bold mb-2"},[_vm._v("Trámites pendientes")]),_c('h3',{staticClass:"secondary--text font-weight-regular mb-0"},[_vm._v(" "+_vm._s(_vm.data.length)+" resultados ")]),_c('v-spacer')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mt-3 mb-3",attrs:{"rounded":"","outlined":"","hide-details":"","label":"Buscar","prepend-inner-icon":"mdi-magnify","clearable":""},model:{value:(_vm.searchProcedures),callback:function ($$v) {_vm.searchProcedures=$$v},expression:"searchProcedures"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"item-key":"id","search":_vm.searchProcedures},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("birthdate")(item.created_at))+" ")]}},{key:"item.user.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+_vm._s(" ")+_vm._s(item.user.lastname)+" ")]}},{key:"item.expires_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("birthdate")(item.expires_at))+" ")]}},{key:"item.status_service",fn:function(ref){
var item = ref.item;
return [(item.status_service == 'Documentos Revisados')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"success"}},[_vm._v(" Revisados ")]):_vm._e(),(item.status_service == 'Nuevo tramite')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_vm._v(" Nuevo Trámite ")]):_vm._e(),(item.status_service == 'En revisión')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_vm._v(" En revisión ")]):_vm._e(),(item.status_service == 'En seguimiento')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_vm._v(" En seguimiento ")]):_vm._e(),(item.status_service == 'Nueva actualización')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_vm._v(" Nueva actualización")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 my-1",attrs:{"fab":"","outlined":"","color":"primary","small":""},on:{"click":function($event){return _vm.goToDetail(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver documentación")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.calcularDias(item.created_at) > 60)?_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 my-1",attrs:{"fab":"","outlined":"","color":"accent","small":""},on:{"click":function($event){return _vm.deleteListProcedure(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-archive ")])],1)],1):_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 my-1",attrs:{"fab":"","disabled":"","outlined":"","color":"accent","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-archive ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Archivar trámite")])])]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"align-center justify-center d-flex py-12",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"empty","size":"40"}},[_vm._v("mdi-flask-empty-outline")]),_c('h4',{staticClass:"empty--text mt-5"},[_vm._v(" No hay información disponible para mostrar ")])],1)],1)]},proxy:true}])})],1),_c('v-dialog',{attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es","min":new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.setExpire()}}},[_vm._v(" Aceptar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }