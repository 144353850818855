<template>
  <v-container fluid class="">
    <v-row align="center" justify="center" class="pt-6">
      <p class="darkgray--text text-center text--lighten-1 py-3 boguelight">
        Resultados de tu búsqueda {{ !!searchValue ? "para: " : "" }}
        <b>{{ !!searchValue ? searchValue : "" }}</b>
      </p>
    </v-row>
    <!-- <v-row align="center" justify="center" class="ml-12 mr-12">
      <v-select
        :items="['Más buscados', 'Menos buscados']"
        v-model="ordenar"
        label="Ordernar por:"
      ></v-select>
    </v-row> -->
    <v-row align="stretch">
      <v-col
        cols="12"
        sm="6"
        md="4"
        xl="3"
        class="d-flex flex-column"
        v-for="(item, index) in proceduresList"
        :key="index"
      >
        <tramit-info
          :secretaryTitle="item.dependecy.name"
          :titleText="item.name"
          :descriptionInfo="item.description"
          :clickInfo="() => onPressInfo(item)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.primary-pattern {
  height: 60vh;
  background-color: #d0d0d0;
  background-repeat: repeat;
}
.boguefont {
  font-family: 'Bogue', sans-serif;
}

.boguelight{
  font-family: 'Bogue Light', sans-serif;
}
</style>
<script>
import TramitInfo from "../components/TramitInfo.vue";
import { mapState } from "vuex";
import './../assets/css/style.css';
export default {
  data: () => ({
    ordenar: null,
  }),
  components: { TramitInfo },
  mounted() {
    let params = {
      search: this.searchValue,
    };
    this.$store.dispatch("procedures/setProcedureList", params).then(() => {});
  },
  methods: {
    onPressInfo(item) {
      this.$router.push("/detalle-tramite");
      this.$store.dispatch("procedures/setProcedureData", item).then(() => {});
    },
  },
  computed: {
    ...mapState({
      searchValue: (state) => state.procedures.search,
      proceduresList: (state) => state.procedures.procedureList,
    }),
  },
};
</script>
