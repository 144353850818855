<template>
  <v-card
    elevation="2"
    class="flex d-flex flex-column"
    style="border-radius: 0.5rem; overflow: hidden"
    @click="clickInfo"
  >
    <p
      class="
        justify-center
        align-center
        text-center
        mb-0
        font-weight-bold
        grey
        lighten-3
        py-2
        text-uppercase
      "
    >
      <small class="boguelight">
        {{ secretaryTitle }}
      </small>
    </p>
    <v-card-text class="flex pb-0">
      <h3 class="primary--text mt-4 text-center text-uppercase boguefont">
        {{ titleText }}
      </h3>
      <br />
      <p class="font-weight-medium mb-1 text-center boguelight"><b>Descripción</b></p>
      <p class="text-center boguelight">
        {{ descriptionInfo }}
      </p>
    </v-card-text>
    <v-card-actions class="justify-center pb-6 boguelight">
      <v-btn text rounded color="primary" @click.stop="clickInfo"
        >Más información</v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<style>
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
  .TitleT {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: #393e46;
  }
  .textP {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0.07px;
    color: #9b2040;
  }
  .textD {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.07px;
    color: #393e46;
  }
  .textI {
    font-family: "Montserrat";
    font-style: bold;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.07px;
    color: #222831;
  }
  .boguefont {
  font-family: 'Bogue', sans-serif;
  }
  .boguelight{
    font-family: 'Bogue Light', sans-serif;
  }
</style>
<script>
  export default {
    props: {
      secretaryTitle: {
        type: String,
        required: true,
        default: "",
      },
      titleText: {
        type: String,
        required: true,
        default: "",
      },
      descriptionInfo: {
        type: String,
        required: true,
        default: "",
      },
      clickInfo: {
        type: Function,
        required: true,
      },
    },
  };
</script>