<template>
  <v-container fluid class="boguelight">
    <v-row class="pt-7">
      <v-col cols="10">
        <h1>Trámites de la dependencia</h1>
      </v-col>
      <v-col cols="2" class="mt-3">
        <v-btn
          @click="showModalCrearTramite = true"
          rounded
          x-large
          block
          color="primary"
          dark
        >
          Crear trámite
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <h2>{{ procedures.length }} resultados</h2>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="searchProcedures"
          rounded
          outlined
          hide-details
          label="Buscar"
          prepend-inner-icon="mdi-magnify"
          class="mt-3 mb-3"
          clearable
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      cols="12"
      no-data-text="No hay trámites."
      :headers="headers"
      :items="procedures"
      class="elevation-1 mytable"
      :items-per-page="10"
      d-initial-sort
      item-key="id"
      :search="searchProcedures"
    >
      <template v-slot:item.actions="{ item }" class="text-xs-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="EditarTramite(item)" v-on="on" icon text>
              <v-icon class="mr-2" color="primary">mdi-eye</v-icon>
            </v-btn>
          </template>
          Editar {{ item.name }}
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="AbrirEliminarTramite(item)" v-on="on" icon text>
              <v-icon class="mr-2" color="primary">mdi-cancel</v-icon>
            </v-btn>
          </template>
          Eliminar {{ item.name }}
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="EditarRequisitosTramite(item)" v-on="on" icon text>
              <v-icon color="primary">mdi-clipboard-edit-outline</v-icon>
            </v-btn>
          </template>
          Ver Requisitos {{ item.name }}
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="setPayableTramit(item)" v-on="on" icon text>
              <v-icon color="success">mdi-cash-multiple</v-icon>
            </v-btn>
          </template>
          Hacer pagable {{ item.name }}
        </v-tooltip>
      </template>
      <template v-slot:item.price="{ item }" class="text-xs-center">
        $ {{ item.price }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="showModalCrearTramite"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card :tile="$vuetify.breakpoint.smAndDown">
        <v-card-title class="primary white--text justify-center">
          <h4 class="text-center">Crear trámite</h4>
        </v-card-title>
        <v-row>
          <v-col>
            <v-text-field
              class="text-center ml-9 mr-9"
              ref="tramite_nombre"
              v-model="nombre"
              label="Nombre"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              class="text-center ml-9 mr-9"
              ref="tramite_descripcion"
              v-model="descripcion"
              label="Descripción"
              required
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              class="text-center ml-9 mr-9"
              ref="tramite_precio"
              v-model="precio"
              label="Precio"
              required
              :rules="decimalNumber"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              class="text-center ml-9 mr-9"
              ref="tramite_publicoObjetivo"
              v-model="publicoObjetivo"
              label="Público objetivo"
              required
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              class="text-center ml-9 mr-9"
              ref="tramite_tipoTramite"
              v-model="tipoTramite"
              label="Tipo de trámite"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              class="text-center ml-9 mr-9"
              ref="tramite_objetivoTramite"
              v-model="objetivoTramite"
              label="Objetivo del trámite"
              required
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              class="text-center ml-9 mr-9"
              ref="tramite_tiempoServicio"
              v-model="tiempoServicio"
              label="Tiempo de validación del servicio"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              class="text-center ml-9 mr-9"
              :items="['Presencial', 'En línea']"
              ref="tramite_modalidad"
              v-model="modalidad"
              label="Modalidad"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              class="text-center ml-9 mr-9"
              :items="['No pagable', 'Pagable']"
              ref="tramite_mode"
              v-model="mode"
              label="Modo"
            ></v-select>
          </v-col>
        </v-row>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="error"
            text
            @click="CerrarCrearTramite()"
          >
            Cancelar
          </v-btn>
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="primary"
            @click="CrearTramite()"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showModalCrearRequisito"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card :tile="$vuetify.breakpoint.smAndDown">
        <v-card-title class="primary white--text justify-center">
          <h4 class="text-center">{{ inicial }} Requisito</h4>
        </v-card-title>
        <v-row>
          <v-col>
            <v-text-field
              class="text-center ml-9 mr-9"
              v-model="nombre2"
              label="Nombre del requisito*"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              class="text-center ml-9 mr-9"
              v-model="tipo"
              label="Tipo de requisito*"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              class="text-center ml-9 mr-9"
              v-model="category_id"
              :items="categories"
              item-value="id"
              item-text="name"
              label="Categoría"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="justify-start ml-6 mr-6">
          <v-col>
            <v-checkbox
              class="text-center"
              v-model="selected"
              color="primary"
              :label="selected == true ? 'Obligatorio' : 'No obligatorio'"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-card-actions class="justify-center">
          <v-btn color="error" text @click="CerrarCrearRequisito()">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="CrearRequisito()"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showModalEditarTramite"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card :tile="$vuetify.breakpoint.smAndDown"
      class="boguelight">
        <v-card-title class="primary white--text justify-center">
          <h4 class="text-center">Editar trámite</h4>
        </v-card-title>
        <v-row>
          <v-col>
            <v-text-field
              class="text-center ml-9 mr-9"
              v-model="proceduresEdit.name"
              label="Nombre"
              required
              ref="tramite_editName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              class="text-center ml-9 mr-9"
              v-model="proceduresEdit.description"
              label="Descripción"
              required
              ref="tramite_editDesc"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              class="text-center ml-9 mr-9"
              v-model="proceduresEdit.price"
              :rules="decimalNumber"
              label="Precio"
              required
              ref="tramite_editPrice"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              class="text-center ml-9 mr-9"
              v-model="proceduresEdit.objetive"
              label="Público objetivo"
              required
              ref="tramite_editObj"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              class="text-center ml-9 mr-9"
              v-model="proceduresEdit.validate_service"
              label="Tiempo de validación del servicio"
              required
              ref="tramite_editVal"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              class="text-center ml-9 mr-9"
              v-model="proceduresEdit.type"
              label="Tipo de trámite"
              required
              ref="tramite_editType"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              class="text-center ml-9 mr-9"
              v-model="proceduresEdit.serves_for"
              label="Objetivo del trámite"
              required
              ref="tramite_editObjTr"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              class="text-center ml-9 mr-9"
              v-model="proceduresEdit.modality"
              :items="['Presencial', 'En línea']"
              label="Modalidad"
              required
              ref="tramite_editModal"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              class="text-center ml-9 mr-9"
              v-model="proceduresEdit.mode"
              :items="['Pagable', 'No pagable']"
              label="Modo"
              required
              ref="tramite_editMode"
            ></v-select>
          </v-col>
        </v-row>
        <v-card-actions class="justify-center">
          <v-btn color="error" text @click="CerrarEditarTramite()">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="EditarTramiteConfirmar()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showModalEditarRequisitosTramite"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card :tile="$vuetify.breakpoint.smAndDown"
      class="boguelight">
        <v-card-title class="primary white--text justify-center">
          <h4 class="text-center">Requisitos</h4>
        </v-card-title>
        <v-row class="justify-center">
          <v-col cols="11" class="centrado">
            <v-btn
              class="mx-2"
              @click="showModalCreateCategory = true"
              rounded
              color="info"
              dark
            >
              Crear Categoría
            </v-btn>
            <v-btn @click="ModalCrearRequisito()" rounded color="primary" dark>
              Crear Requisito
            </v-btn>
          </v-col>
          <v-card class="mx-auto" max-width="1200">
            <v-data-table
              cols="12"
              no-data-text="No hay Requisitos."
              :headers="headersRequirements"
              :items="tableRequirements"
              class="elevation-1 mytable"
              :items-per-page="10"
              d-initial-sort
              item-key="id"
            >
              <template v-slot:item.selected="{ item }" class="text-center">
                <v-checkbox
                  disabled
                  class="text-center"
                  v-model="item.is_requirement"
                ></v-checkbox>
              </template>
              <template v-slot:item.actions="{ item }" class="text-xs-center">
                <v-row class="text-justify">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="ModalCrearRequisito(item)"
                        v-on="on"
                        icon
                        text
                      >
                        <v-icon class="mr-2" color="primary"
                          >mdi-pencil-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    Editar {{ item.name }}
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="AbrirEliminarRequisito(item)"
                        v-on="on"
                        icon
                        text
                      >
                        <v-icon class="mr-2" color="primary">mdi-cancel</v-icon>
                      </v-btn>
                    </template>
                    Eliminar {{ item.name }}
                  </v-tooltip>
                </v-row>
              </template>
            </v-data-table>
          </v-card>
        </v-row>
        <v-card-actions class="justify-center">
          <v-btn color="error" text @click="CerrarEditarRequisito()">
            Salir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showModalCreateCategory"
      max-width="800"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card :tile="$vuetify.breakpoint.smAndDown">
        <v-card-title class="primary white--text justify-center">
          <h4 class="text-center">Crear categoría</h4>
        </v-card-title>
        <v-row>
          <v-col>
            <v-text-field
              class="text-center ml-9 mr-9"
              v-model="nameCategory"
              label="Nombre de la categoría"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              class="text-center ml-9 mr-9"
              v-model="priceCategory"
              label="Precio"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions class="justify-center">
          <v-btn color="error" text @click="showModalCreateCategory = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="createCategory()"> Crear </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showModalEliminarTramite"
      max-width="800"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card :tile="$vuetify.breakpoint.smAndDown"
      class="boguelight">
        <v-card-title class="primary white--text justify-center">
          <h4 class="text-center">Eliminar trámite</h4>
        </v-card-title>

        <h3 class="text-center mt-5 mb-3">
          ¿Seguro que desea eliminar este trámite?
        </h3>

        <v-card-actions class="justify-center">
          <v-btn color="error" text @click="showModalEliminarTramite = false">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="EliminarTramite()"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="openDialogDeleteRequirement"
      max-width="800"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card :tile="$vuetify.breakpoint.smAndDown">
        <v-card-title class="primary white--text justify-center">
          <h4 class="text-center">Eliminar Requisito</h4>
        </v-card-title>

        <h3 class="text-center mt-5 mb-3">
          ¿Seguro que desea eliminar este requisito?
        </h3>

        <v-card-actions class="justify-center">
          <v-btn
            color="error"
            text
            @click="openDialogDeleteRequirement = false"
          >
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="EliminarRequisito()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  mounted() {
    this.cargarDatos();
  },
  data: () => ({
    loading: false,
    categories: [],
    showModalCreateCategory: false,
    nameCategory: null,
    priceCategory: 0,
    nombre: "",
    nombre2: "",
    category_id: null,
    searchProcedures: "",
    descripcion: "",
    tipo: "",
    precio: "",
    publicoObjetivo: "",
    tipoTramite: "",
    objetivoTramite: "",
    modalidad: "",
    tiempoServicio: "",
    tableRequirements: [],
    procedureid: "",
    requirementId: "",
    inicial: "",
    mode: "",
    selected: true,
    openDialogDeleteRequirement: false,
    showModalCrearRequisito: false,
    showModalCrearTramite: false,
    showModalEliminarTramite: false,
    showModalEditarRequisitosTramite: false,
    showModalEditarTramite: false,
    headers: [
      {
        text: "Nombre",
        sortable: true,
        align: "center",
        value: "name",
        class: "black--text",
      },
      {
        text: "Precio",
        align: "center",
        sortable: true,
        value: "price",
        class: "black--text",
      },
      {
        text: "Objetivo del trámite",
        sortable: true,
        align: "center",
        value: "objetive",
        class: "black--text",
      },
      {
        text: "Modalidad",
        sortable: true,
        align: "center",
        value: "modality",
        class: "black--text",
      },
      {
        text: "Modo",
        sortable: true,
        align: "center",
        value: "mode",
        class: "black--text",
      },
      {
        text: "Acciones",
        sortable: false,
        align: "center",
        value: "actions",
        class: "black--text",
      },
    ],
    headersRequirements: [
      {
        text: "Obligatorio",
        value: "selected",
        align: "center",
        sortable: false,
        class: "black--text",
      },
      {
        text: "Nombre",
        sortable: true,
        align: "center",
        value: "name",
        class: "black--text",
      },
      {
        text: "Tipo",
        align: "center",
        sortable: true,
        value: "type",
        class: "black--text",
      },
      {
        text: "Categoría",
        align: "center",
        sortable: true,
        value: "category.name",
        class: "black--text",
      },
      {
        text: "Acciones",
        sortable: false,
        align: "center",
        value: "actions",
        class: "black--text",
      },
    ],
    procedures: [],
    proceduresEdit: [],
    idborrar: "",
    decimalNumber: [
      (v) =>
        /^(\d*\.)?\d+$/.test(v) || "Este campo debe ser numérico y positivo",
    ],
  }),
  methods: {
    ModalCrearRequisito(item) {
      if (item != null) {
        this.inicial = "Editar";
        this.nombre2 = item.name;
        this.selected = item.is_requirement;
        this.tipo = item.type;
        this.requirementId = item.id;
      } else {
        this.inicial = "Crear";
        this.nombre2 = "";
        this.tipo = "";
        this.requirementId = "";
      }
      this.showModalCrearRequisito = true;
    },
    setPayableTramit(item) {
      if (item.mode == "No Pagable") {
        item.mode = "Pagable";
      } else {
        item.mode = "No Pagable";
      }
      this.$store.dispatch(
        "alerts/setSnackbarText",
        "El tramite es " + item.mode
      );
      this.$store.dispatch("alerts/setSnackbarColor", "success");
      this.$store.dispatch("alerts/setSnackbar", true);
    },
    createCategory() {
      let self = this;
      const params = {
        name: this.nameCategory,
        price: this.priceCategory,
      };
      this.$store.dispatch("user/storeCategory", params).then((data) => {
        console.log(data);
        self.showModalCreateCategory = false;
        self.nameCategory = null;
        self.priceCategory = 0;
        self.$store.dispatch("procedures/getCategories").then((data) => {
          this.categories = data;
        });
      });
    },
    getRequirements(item) {
      let self = this;
      this.procedureid = item;
      const params = {
        id: item,
      };
      this.$store
        .dispatch("procedures/getProcedureDetail", params)
        .then((data) => {
          self.tableRequirements = data.requirements;
        });
    },
    EditarRequisitosTramite(item) {
      this.getRequirements(item.id);
      this.proceduresEdit = item;
      this.showModalEditarRequisitosTramite = true;
    },
    AbrirEliminarTramite(item) {
      this.idborrar = item.id;
      this.showModalEliminarTramite = true;
      console.log(item.id);
    },
    cargarDatos() {
      this.$store.dispatch("user/getTramitDepency").then((data) => {
        this.procedures = data;
      });
      this.$store.dispatch("procedures/getCategories").then((data) => {
        this.categories = data;
      });
    },
    EditarTramiteConfirmar() {
      if (!/^(\d*\.)?\d+$/.test(this.proceduresEdit.price)) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Precio invalido, por favor ingrese un precio válido."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_editPrice.focus();
        return;
      }
      if (this.proceduresEdit.name == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Nombre vacío, por favor ingrese un nombre."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_editName.focus();
      } else if (this.proceduresEdit.description == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Descripción vacía, por favor ingrese una descripción."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_editDesc.focus();
      } else if (this.proceduresEdit.price == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Precio vacío, por favor ingrese el precio."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_editPrice.focus();
      } else if (this.proceduresEdit.objetive == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Público objetivo vacío, por favor ingrese el público objetivo."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_editObj.focus();
      } else if (this.proceduresEdit.validate_service == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Tiempo de validación vacío, por favor ingrese un tiempo de validación."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_editVal.focus();
      } else if (this.proceduresEdit.type == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Tipo de trámite vacío, por favor ingrese un tipo de trámite."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_editType.focus();
      } else if (this.proceduresEdit.serves_for == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Objetivo del trámite vacío, por favor ingrese el objetivo del trámite."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_editObjTr.focus();
      } else if (this.proceduresEdit.modality == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Modalidad vacía, por favor ingrese una modalidad."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_editModal.focus();
      } else if (this.proceduresEdit.mode == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Modo del trámite vacío, por favor ingrese el modo del trámite."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_editMode.focus();
      } else {
        this.$store
          .dispatch("user/editProcedure", this.proceduresEdit)
          .then(() => {
            this.$store.dispatch(
              "alerts/setSnackbarText",
              "Trámite editado correctamente."
            );
            this.$store.dispatch("alerts/setSnackbarColor", "success");
            this.$store.dispatch("alerts/setSnackbar", true);
            this.showModalEditarTramite = false;
            this.cargarDatos();
          })
          .catch(() => {
            this.$store.dispatch("alerts/setSnackbarText", "Algo salió mal.");
            this.$store.dispatch("alerts/setSnackbarColor", "error");
            this.$store.dispatch("alerts/setSnackbar", true);
          });
      }
    },
    CrearTramite() {
      if (this.nombre == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Nombre vacío, por favor ingrese un nombre."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_nombre.focus();
        return;
      } else if (this.descripcion == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Descripción vacía, por favor ingrese una descripción."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_descripcion.focus();
        return;
      } else if (this.precio == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Precio vacío, por favor ingrese el precio."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_precio.focus();
        return;
      } else if (!/^(\d*\.)?\d+$/.test(this.precio)) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Precio invalido, por favor ingrese un precio válido."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_precio.focus();
        return;
      } else if (this.publicoObjetivo == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Público objetivo vacío, por favor ingrese el público objetivo."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_publicoObjetivo.focus();
        return;
      } else if (this.tipoTramite == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Tipo de trámite vacío, por favor ingrese un tipo de trámite."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_tipoTramite.focus();
        return;
      } else if (this.objetivoTramite == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Objetivo del trámite vacío, por favor ingrese el objetivo del trámite."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_objetivoTramite.focus();
        return;
      } else if (this.tiempoServicio == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Tiempo de validación vacío, por favor ingrese un tiempo de validación."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_tiempoServicio.focus();
        return;
      } else if (this.modalidad == "" || this.modalidad === null) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Modalidad vacía, por favor ingrese una modalidad."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_modalidad.focus();
        return;
      } else if (this.mode == "" || this.mode === null) {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Modo vacío, por favor ingrese una modo."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
        this.$refs.tramite_mode.focus();
        return;
      } else {
        if (!this.loading) {
          this.loading = true;

          let params = {
            name: this.nombre,
            description: this.descripcion,
            price: this.precio,
            objetive: this.publicoObjetivo,
            validate_service: this.tiempoServicio,
            type: this.tipoTramite,
            serves_for: this.objetivoTramite,
            modality: this.modalidad,
            mode: this.mode,
            dependency_id: this.loguedUser.dependency_id,
          };
          this.$store
            .dispatch("user/registerProcedure", params)
            .then(() => {
              this.$store.dispatch(
                "alerts/setSnackbarText",
                "Trámite creado correctamente."
              );
              this.$store.dispatch("alerts/setSnackbarColor", "success");
              this.$store.dispatch("alerts/setSnackbar", true);
              this.showModalCrearTramite = false;
              this.cargarDatos();
              this.CerrarCrearTramite();
            })
            .catch(() => {
              this.$store.dispatch("alerts/setSnackbarText", "Algo salió mal.");
              this.$store.dispatch("alerts/setSnackbarColor", "error");
              this.$store.dispatch("alerts/setSnackbar", true);
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false;
              }, 3000);
            });
        }
      }
    },
    CrearRequisito() {
      if (this.nombre2 == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Nombre de requisito vacío, por favor ingreselo bien."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else if (this.tipo == "") {
        this.$store.dispatch(
          "alerts/setSnackbarText",
          "Tipo vacio, por favor ingrese un Tipo."
        );
        this.$store.dispatch("alerts/setSnackbarColor", "warning");
        this.$store.dispatch("alerts/setSnackbar", true);
      } else {
        if (this.requirementId == "") {
          let params = {
            procedure_id: this.procedureid,
            name: this.nombre2,
            tipo: this.tipo,
            selected: this.selected,
            category_id: this.category_id,
          };
          this.$store
            .dispatch("user/createRequirement", params)
            .then(() => {
              this.$store.dispatch(
                "alerts/setSnackbarText",
                "Requisito registrado correctamente."
              );
              this.$store.dispatch("alerts/setSnackbarColor", "success");
              this.$store.dispatch("alerts/setSnackbar", true);
              this.showModalCrearRequisito = false;
              this.getRequirements(this.procedureid);
              this.category_id = null;
            })
            .catch(() => {
              this.$store.dispatch("alerts/setSnackbarText", "Algo salió mal.");
              this.$store.dispatch("alerts/setSnackbarColor", "error");
              this.$store.dispatch("alerts/setSnackbar", true);
            });
        } else {
          let params = {
            procedure_id: this.procedureid,
            id: this.requirementId,
            name: this.nombre2,
            tipo: this.tipo,
            selected: this.selected,
          };
          this.$store
            .dispatch("user/updateRequirement", params)
            .then(() => {
              this.$store.dispatch(
                "alerts/setSnackbarText",
                "Requisito registrado correctamente."
              );
              this.$store.dispatch("alerts/setSnackbarColor", "success");
              this.$store.dispatch("alerts/setSnackbar", true);
              this.showModalCrearRequisito = false;
              this.getRequirements(this.procedureid);
            })
            .catch(() => {
              this.$store.dispatch("alerts/setSnackbarText", "Algo salió mal.");
              this.$store.dispatch("alerts/setSnackbarColor", "error");
              this.$store.dispatch("alerts/setSnackbar", true);
            });
        }
      }
    },
    AbrirEliminarRequisito(item) {
      this.requirementId = item.id;
      this.openDialogDeleteRequirement = true;
    },
    EliminarRequisito() {
      let params = {
        id: this.requirementId,
      };
      this.$store
        .dispatch("user/deleteRequirement", params)
        .then(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Requisito eliminado correctamente."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "success");
          this.$store.dispatch("alerts/setSnackbar", true);
          this.getRequirements(this.procedureid);
        })
        .catch(() => {
          this.$store.dispatch("alerts/setSnackbarText", "Algo salió mal.");
          this.$store.dispatch("alerts/setSnackbarColor", "error");
          this.$store.dispatch("alerts/setSnackbar", true);
        });
      this.openDialogDeleteRequirement = false;
    },
    EditarTramite(item) {
      this.proceduresEdit = item;
      this.showModalEditarTramite = true;
    },
    EliminarTramite() {
      let params = {
        id: this.idborrar,
      };
      this.$store
        .dispatch("user/deleteProcedure", params)
        .then(() => {
          this.$store.dispatch(
            "alerts/setSnackbarText",
            "Trámite eliminado correctamente."
          );
          this.$store.dispatch("alerts/setSnackbarColor", "success");
          this.$store.dispatch("alerts/setSnackbar", true);
          this.showModalCrearTramite = false;
          this.cargarDatos();
        })
        .catch(() => {
          this.$store.dispatch("alerts/setSnackbarText", "Algo salió mal.");
          this.$store.dispatch("alerts/setSnackbarColor", "error");
          this.$store.dispatch("alerts/setSnackbar", true);
        });
      this.showModalEliminarTramite = false;
    },
    CerrarCrearRequisito() {
      this.showModalCrearRequisito = false;
      this.nombre2 = "";
      this.tipo = "";
    },
    CerrarCrearTramite() {
      this.showModalCrearTramite = false;
      this.nombre = "";
      this.descripcion = "";
      this.precio = "";
      this.publicoObjetivo = "";
      this.tipoTramite = "";
      this.objetivoTramite = "";
      this.modalidad = "";
      this.tiempoServicio = "";
    },
    CerrarEditarTramite() {
      this.showModalEditarTramite = false;
      this.nombre = "";
      this.descripcion = "";
      this.precio = "";
      this.publicoObjetivo = "";
      this.tipoTramite = "";
      this.objetivoTramite = "";
      this.modalidad = "";
      this.tiempoServicio = "";
    },
    CerrarEditarRequisito() {
      this.showModalEditarRequisitosTramite = false;
      this.requisito = "";
    },
  },
  computed: {
    ...mapState({
      loguedUser: (state) => state.user.loguedUser,
    }),
  },
};
</script>
<style scoped>
.centrado {
  text-align: end;
}
</style>
