/* eslint-disable vue/no-parsing-error */ /* eslint-disable vue/no-parsing-error
*/
<template>
  <v-card class="rounded-lg mt-10 boguelight" flat>
    <v-card-title class="d-block pt-8">
      <h2 class="secondary--text font-weight-bold mb-2">Trámites pendientes</h2>
      <h3 class="secondary--text font-weight-regular mb-0">
        {{ data.length }} resultados
      </h3>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-col cols="12">
      <v-text-field
        v-model="searchProcedures"
        rounded
        outlined
        hide-details
        label="Buscar"
        prepend-inner-icon="mdi-magnify"
        class="mt-3 mb-3"
        clearable
      ></v-text-field>
    </v-col>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="id"
        :search="searchProcedures"
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | birthdate }}
        </template>
        <template v-slot:item.user.name="{ item }">
          {{ item.user.name }}{{ " " }}{{ item.user.lastname }}
        </template>
        <template v-slot:item.expires_at="{ item }">
          {{ item.expires_at | birthdate }}
        </template>
        <template v-slot:item.status_service="{ item }">
          <v-chip
            class="ma-2"
            color="success"
            v-if="item.status_service == 'Documentos Revisados'"
          >
            Revisados
          </v-chip>
           <v-chip class="ma-2" color="warning"  v-if="item.status_service == 'Nuevo tramite'"> Nuevo Trámite </v-chip>
           <v-chip class="ma-2" color="warning"  v-if="item.status_service == 'En revisión'"> En revisión </v-chip>
           <v-chip class="ma-2" color="warning"  v-if="item.status_service == 'En seguimiento'"> En seguimiento </v-chip>
           <v-chip class="ma-2" color="warning"  v-if="item.status_service == 'Nueva actualización'"> Nueva actualización</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                outlined
                class="mx-1 my-1"
                color="primary"
                small
                @click="goToDetail(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon> mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Ver documentación</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-if="calcularDias(item.created_at) > 60">
                <v-btn
                  class="mx-1 my-1"
                  fab
                  @click="deleteListProcedure(item)"
                  outlined
                  color="accent"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-archive </v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  class="mx-1 my-1"
                  fab
                  disabled
                  outlined
                  color="accent"
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-archive </v-icon>
                </v-btn>
              </div>
            </template>
            <span>Archivar trámite</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-row
            align="center"
            justify="center"
            class="align-center justify-center d-flex py-12"
          >
            <v-col cols="12">
              <v-icon color="empty" size="40">mdi-flask-empty-outline</v-icon>
              <h4 class="empty--text mt-5">
                No hay información disponible para mostrar
              </h4>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      :return-value.sync="date"
      persistent
      width="290px"
    >
      <v-date-picker
        v-model="date"
        scrollable
        locale="es"
        :min="
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        "
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dialog = false"> Cancelar </v-btn>
        <v-btn text color="primary" @click="setExpire()"> Aceptar </v-btn>
      </v-date-picker>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => ({
    headers: [
      {
        text: "Folio",
        align: "start",
        sortable: true,
        value: "folio",
      },
      {
        text: "Contribuyente",
        value: "user.name",
        sortable: false,
        align: "center",
      },
      {
        text: "Trámite solicitado",
        value: "procedure.name",
        sortable: true,
        align: "center",
      },
      {
        text: "Fecha de creación",
        value: "created_at",
        sortable: true,
        align: "center",
      },
      {
        text: "Fecha asignada",
        value: "expires_at",
        sortable: true,
        align: "center",
      },
      {
        text: "Estatus documentación",
        value: "status_service",
        sortable: false,
        align: "center",
      },
      { text: "Acciones", value: "actions", sortable: false, align: "center" },
    ],
    search: "",
    searchProcedures: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dialog: false,
    procedureToExpire: {},
  }),
  methods: {
    goToDetail(item) {
      let params = {
        id: item.id,
      };
      this.$store.dispatch("user/setProcedureAdminReview", true);
      this.$store
        .dispatch("user/getPersonalProcedureDetail", params)
        .then(() => {
          this.$router.push("/my-tramit-detail");
        });
    },
    deleteListProcedure(item) {
      console.log(item);
      let params = {
        id: item.id,
      };
      this.$store.dispatch("user/deleteListProcedure", params).then(() => {
        this.$store.dispatch("user/getAdminData2").then(() => {
          this.$store.dispatch("alerts/setSnackbarText", "Tramite Archivado correctamente");
          this.$store.dispatch("alerts/setSnackbarColor", "success");
          this.$store.dispatch("alerts/setSnackbar", true);
        });
      });
    },
    openDialog(item) {
      var self = this;
      self.procedureToExpire = item;
      self.dialog = true;
    },
    setExpire() {
      var self = this;
      let params = {
        id: self.procedureToExpire.id,
        expire_at: self.date,
      };
      this.$store.dispatch("user/setExpire", params).then(() => {
        this.$store.dispatch("alerts/setSnackbarText", "Fecha actualizada");
        this.$store.dispatch("alerts/setSnackbarColor", "success");
        this.$store.dispatch("alerts/setSnackbar", true);
        self.procedureToExpire = {};
        self.dialog = false;
        this.$store.dispatch("user/getAdminData").then(() => {});
      });
    },
    calcularDias(date) {
      var now = moment();
      var end = moment(date);
      return now.diff(end, "days");
    },
  },
  computed: {
    // dataFiltered() {
    //   return this.data.filter((item) => {
    //     return item.procedure.name.toLowerCase();
    //   });
    // },
  },
};
</script>
